angular.module('llax')
    .controller('CommunicationChannelsController',
        function($controller, $location, $log, $modal, $rootScope, $routeParams, $scope, $timeout, growl, ChannelService,
            CommunicationChannelResource, CommunicationChannelSubDestinationsAndBlockedReviewersResource, CommunicationChannelTypesResource, CommunicationChannelTemplatesResource, Auth,
            InputTemplatesService, OrganizationService, PublicationResource, RepublishResource, ReactBridge) {

            var SECRET_PLACEHOLDER = "......";

            $scope.init = function () {
                CommunicationChannelTypesResource.get({}, function (response) {
                    response = response.toJSON();

                    $scope.channelsVerification = response;
                    $scope.channelTypes = Object.keys(response);

                    if ($rootScope.organization) {
                        $scope.loadAllChannels();
                    } else {
                        var organizationLoaded = false;
                        OrganizationService.getOrganization().subscribe(function(organization) {
                            if (!organizationLoaded) {
                                organizationLoaded = true;
                                $rootScope.organization = angular.copy(organization);
                                $scope.loadAllChannels();
                            }
                        });
                    }

                });
            };

            $scope.init();

            $controller('PublicationTasksController', {
                $scope: $scope
            });
            $controller('SubscriptionsController', {
                $scope: $scope
            });
            $controller('MessagingController', {
                $scope: $scope
            });

            $rootScope.loadDataModel();

            ChannelService.register(ChannelService.MESSAGING_ASSET_CHANGED_EVENT);

            $scope.$on('$destroy', function() {
                ChannelService.unregister(ChannelService.MESSAGING_ASSET_CHANGED_EVENT);
            });

            $scope.$on('channelMessageReceived', function(event, eventData) {
                if (eventData.event === ChannelService.MESSAGING_ASSET_CHANGED_EVENT) {
                    var messagingAsset = eventData.data.messagingAsset;
                    var channelId = messagingAsset.channelId;

                    if (channelId) {
                        $scope.updateMessageCounter(channelId);
                    }
                }
            });

            function setPlans(plans) {
                $scope.plans = plans;
                if (!_.isEmpty($scope.plans)) {
                    _.forEach($scope.plans, function(plan) {
                        if (!_.isEmpty(plan.planAttributes)) {
                            $rootScope.prepareAttributes(plan.planAttributes, true);
                        }
                    });
                }
            }

            setPlans($rootScope.communicationPlans);
            $scope.$on('dataModelLoaded', function() {
                $scope.loadAllChannels();
                setPlans($rootScope.communicationPlans);
            });

            $scope.getPlan = function(channel) {
                return $scope.plans[channel.plan];
            };

            $scope.getPlanLabel = function(channel) {
                var plan = $scope.getPlan(channel);
                if (plan) {
                    return (plan.label || plan.name);
                } else if (channel.format) {
                    return $rootScope.translateValue('COMMUNICATION_CHANNELS.FORMATS.', channel.format);
                } else {
                    return "";
                }
            };

            $rootScope.supportsInbound = function (channel) {
                if (_.isNil(channel.direction)) {
                    return false;
                }
                return channel.direction !== "OUTBOUND_ONLY";
            };

            $rootScope.supportsOutbound = function (channel) {
                if (_.isNil(channel.direction)) {
                    return false;
                }
                return channel.direction !== "INBOUND_ONLY";
            };

            $scope.hasCreatePermission = function() {
                return Auth.hasChannelPermission("create", null);
            };

            $scope.hasActivatePermission = function(channel) {
                return Auth.hasChannelPermission("activate", channel);
            };

            $scope.hasEditPermission = function(channel) {
                return Auth.hasChannelPermission("edit", channel);
            };

            $scope.hasEditSubDestinationPermission = function(channel) {
                return Auth.hasChannelPermission("edit_sub_destinations", channel);
            };

            $scope.hasDeletePermission = function(channel) {
                return Auth.hasChannelPermission("delete", channel);
            };

            $scope.channelsSupportingBlockedReviewers = ['AS2', 'BSYNCED', 'CUSTOM'];
            $scope.templates = [];
            CommunicationChannelTemplatesResource.query(function(response) {
                $scope.templates = response;
            });

            var editChannelOpts = function(channel, title) {
                return {
                    templateUrl: 'tpl/edit-communicationchannel.tpl.html',
                    controller: 'EditChannelCtrl',
                    windowClass: 'communicationchannel-modal',
                    resolve: {
                        channel: function() {
                            return channel;
                        },
                        title: function() {
                            return title;
                        },
                        plans: function() {
                            return $scope.plans;
                        },
                        types: function() {
                            return $scope.channelTypes;
                        },
                        templates: function() {
                            return $scope.templates;
                        },
                        channelsVerification: function() {
                            return $scope.channelsVerification;
                        }
                    }
                };
            };

            $scope.channels = {};
            $scope.hasChannels = false;
            $scope.isChannelsLoading = true;
            $scope.orderPredicate = 'name';

            $scope.updateMessageCounter = function(channelId) {
                CommunicationChannelResource.get({channelId: channelId}, {},
                    function(channel) {
                        if (channel) {
                            $scope.channels[channel.id].numberOfPendingIncomingMessages = channel.numberOfPendingIncomingMessages;
                            $scope.channels[channel.id].countMessagingAssetsCreatedAfter = channel.countMessagingAssetsCreatedAfter;
                        }
                    },
                    function (response) {
                        $rootScope.error = response.data;
                        $scope.status = response.status;
                        growl.error("COMMUNICATION_CHANNELS.LOAD_ERROR_MESSAGE");
                    });
            };

            $scope.loadAllChannels = function() {

                CommunicationChannelResource.query({},
                    function(channels) {
                        if (channels.length > 0) {
                            for (var i = 0; i < channels.length; i++) {
                                var channel = channels[i];
                                channel.needsVerification = !$scope.channelsVerification[channel.type];
                                channel.supportsBlockedReviewers = $scope.channelsSupportingBlockedReviewers.indexOf(channel.type) > -1;
                                convert(channel);
                                $scope.channels[channel.id] = channel;
                            }
                            $scope.hasChannels = true;
                            $scope.isChannelsLoading = false;
                        } else {
                            $scope.hasChannels = false;
                            $scope.isChannelsLoading = false;
                        }
                    },
                    function(response) {
                        $rootScope.error = response.data;
                        $scope.status = response.status;
                        growl.error("COMMUNICATION_CHANNELS.LOAD_ERROR_MESSAGE");
                    });

            };

            $scope.newChannel = function() {
                var modal = $modal.open({
                    template: '<div class="modal-header">' +
                                    '<button id="close-button" type="button" class="close" data-dismiss="modal" data-ng-click="cancel()" data-aria-hidden="true">&times;</button>' +
                                    '<h4 class="modal-title">' +
                                        '<span data-translate>COMMUNICATION_CHANNELS.NEW_TITLE</span>' +
                                    '</h4>' +
                                '</div>' +
                                '<div class="modal-body">' +
                                    '<div class="form-horizontal">' +
                                        '<div data-form-input class="p-10" data-label="COMMUNICATION_CHANNELS.SELECT_TEMPLATE">' +
                                            '<select style="cursor: pointer;" id="select-template" class="form-control"' +
                                            'data-ng-model="selectedName" data-ng-change="selectTemplate(selectedName)">' +
                                                '<option value="" data-translate>COMMUNICATION_CHANNELS.NO_TEMPLATE</option>' +
                                                '<option disabled>-------------------------------------------------------------</option>' +
                                                '<option value="{{type.name}}" ng-repeat="type in templates | filter:{name: \'B-synced\'}">{{type.name}}</option>' +
                                                '<option disabled></option>' +
                                                '<option value="{{type.name}}" ng-repeat="type in templates | filter:{name: \'!B-synced\'}">{{type.name}}</option>' +
                                            '</select>' +
                                            '<span class="error" data-ng-if="hasCombinationError" data-translate-values="{plan : channel.plan}" data-translate>COMMUNICATION_CHANNELS.ERROR_CHANNEL_TYPE_NOT_SUPPORTED_BY_PLAN</span>' +
                                        '</div>' +
                                    '</div>' +
                                '</div>' +
                                '<div class="modal-footer">' +
                                    '<button id="select-template-ok-button" type="button" class="btn btn-sm btn-primary" data-ng-click="ok()" data-translate>ITEM_EDITOR.NEXT_ITEM</button>' +
                                    '<button id="cancel-button" type="button" class="btn btn-sm btn-default" data-ng-click="cancel()" data-translate>CANCEL</button>' +
                                '</div>',
                    controller: function($scope, $modalInstance) {
                        $scope.ok = function() {
                            $modalInstance.close($scope.selectedTemplate);
                        };

                        $scope.selectTemplate = function(n) {
                            $scope.selectedTemplate = _.find($scope.templates, function(t) {
                                return t.name == n;
                            });
                        };

                        $scope.cancel = function() {
                            $modalInstance.dismiss('cancel');
                        };
                    },
                    size: 'sm',
                    windowClass : 'select-cc-template-editor',
                    scope: $scope
                });
                modal.result.then(function(template) {
                    var channel = {};
                    channel = angular.extend(channel, template);
                    var modal = $modal.open(editChannelOpts(channel, "COMMUNICATION_CHANNELS.NEW_TITLE"));
                    modal.result.then(function(channel) {
                        $scope.saveChannel(channel);
                    }).finally(function() {
                        $location.displayUrl("/communicationchannels");
                        $rootScope.reloadAfterDataModelUpdate();
                    });
                });
            };

            $scope.editChannel = function(channel) {
                if (channel.type == "BSYNCED"){
                    channel.apiToken = "";
                    if (channel.hasApiToken){
                        channel.apiToken = SECRET_PLACEHOLDER;
                    }
                }
                if (channel.type == "SFTP"){
                    channel.password = "";
                    if (channel.hasPassword){
                        channel.password = SECRET_PLACEHOLDER;
                    }
                    channel.privateKey = "";
                    if (channel.hasPrivateKey){
                        channel.privateKey = SECRET_PLACEHOLDER;
                    }
                }
                $location.displayUrl("/communicationchannels/" + channel.id);
                var modal = $modal.open(editChannelOpts(channel, "COMMUNICATION_CHANNELS.EDIT_TITLE"));
                modal.result.then(function(channel) {
                    if (channel.type == "BSYNCED" && channel.apiToken == SECRET_PLACEHOLDER){
                        delete channel.apiToken;
                    }
                    if (channel.type == "SFTP") {
                        if (channel.password == SECRET_PLACEHOLDER) {
                            delete channel.password;
                        }
                        if (channel.privateKey == SECRET_PLACEHOLDER) {
                            delete channel.privateKey;
                        }
                    }
                    $timeout(function () {
                        $scope.saveChannel(channel);
                    }, 0);
                }).finally(function() {
                    $location.displayUrl("/communicationchannels");
                    $rootScope.reloadAfterDataModelUpdate();
                });
            };

            $scope.resetCountKeyDate = function(channel) {
                console.log("about to reset count date for channel: ", channel);

                CommunicationChannelResource.resetcountkeydate({
                        channelId: channel.id
                    }, null,
                    function(channel) {
                        $scope.channels[channel.id] = channel;
                        growl.success("COMMUNICATION_CHANNELS.RESET_COUNT_KEY_DATE_SUCCESS_MESSAGE");
                    },
                    function(response) {
                        $rootScope.error = response.data;
                        $scope.status = response.status;
                        growl.error("COMMUNICATION_CHANNELS.RESET_COUNT_KEY_DATE_ERROR_MESSAGE");
                    });
            };

            $scope.saveChannel = function(channel) {

                console.log("about to save channel: ", channel);

                $scope.clearConnectionError(channel);

                if (!_.isEmpty(channel.subDestinations)) {
                    channel = angular.copy(channel);
                    var subDestinations = {};
                    for (var i = 0; i < channel.subDestinations.length; i++) {
                        var key = channel.subDestinations[i].key;
                        var label = channel.subDestinations[i].label || "";
                        if (_.isEmpty(key)) {
                            continue;
                        }
                        subDestinations[key] = label;
                    }
                    channel.subDestinations = subDestinations;
                } else {
                    channel.subDestinations = null;
                }

                if (!_.isEmpty(channel.blockedReviewers)) {
                    channel = angular.copy(channel);
                    var blockedReviewers = {};
                    for (var j = 0; j < channel.blockedReviewers.length; j++) {
                        var blockedReviewerKey = channel.blockedReviewers[j].key;
                        var blockedReviewerLabel = channel.blockedReviewers[j].label || "";
                        if (_.isEmpty(blockedReviewerKey)) {
                            continue;
                        }
                        blockedReviewers[blockedReviewerKey] = blockedReviewerLabel;
                    }
                    channel.blockedReviewers = blockedReviewers;
                } else {
                    channel.blockedReviewers = null;
                }

                if( !$scope.hasEditPermission(channel) && $scope.hasEditSubDestinationPermission(channel)){
                    CommunicationChannelSubDestinationsAndBlockedReviewersResource.save(channel,
                        function(channel) {
                            $scope.channels[channel.id] = channel;
                            growl.success("COMMUNICATION_CHANNELS.SAVE_SUCCESS_MESSAGE");
                            $scope.loadAllChannels();
                        },
                        function(response) {
                            $rootScope.error = response.data;
                            $scope.status = response.status;
                            growl.error("COMMUNICATION_CHANNELS.SAVE_ERROR_MESSAGE");
                        });
                } else{
                    CommunicationChannelResource.save(channel,
                        function(channel) {
                            $scope.channels[channel.id] = channel;
                            growl.success("COMMUNICATION_CHANNELS.SAVE_SUCCESS_MESSAGE");
                            $scope.loadAllChannels();
                        },
                        function(response) {
                            $rootScope.error = response.data;
                            $scope.status = response.status;
                            growl.error("COMMUNICATION_CHANNELS.SAVE_ERROR_MESSAGE");
                        });
                }

            };

            $scope.$on('deleteChannel', function(scope, channel) {
                console.log("about to delete channel: ", channel);

                CommunicationChannelResource.remove({
                        channelId: channel.id
                    }, null,
                    function(response) {
                        delete $scope.channels[channel.id];
                        growl.success("COMMUNICATION_CHANNELS.DELETE_SUCCESS_MESSAGE");
                        $scope.loadAllChannels();
                    },
                    function(response) {
                        $rootScope.error = response.data;
                        $scope.status = response.status;
                        growl.error("COMMUNICATION_CHANNELS.DELETE_ERROR_MESSAGE");
                    });
            });

            $scope.deleteChannel = function(channel) {
                var modalInstance = $modal.open({
                    templateUrl: 'tpl/confirm-delete-communicationchannel.tpl.html',
                    controller: 'ModalInstanceCtrl',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return channel;
                        }
                    }
                });
            };

            $scope.$on('deactivateChannel', function(scope, channel) {
                console.log("about to deactivate channel: ", channel);
                $scope.deactivateChannel(channel);
            });

            $scope.toggleChannelActivation = function(channel) {
                if (!channel.active) {
                    $scope.activateChannel(channel);
                } else {
                    var modalInstance = $modal.open({
                        templateUrl: 'tpl/confirm-deactivate-communicationchannel.tpl.html',
                        controller: 'ModalInstanceCtrl',
                        backdrop: true,
                        resolve: {
                            data: function() {
                                return channel;
                            }
                        }
                    });
                }
            };

            $scope.activateChannel = function(channel) {

                console.log("about to activate channel: ", channel);

                if ($scope.hasConnectionError(channel)) {
                    growl.warning("COMMUNICATION_CHANNELS.CONNECTION_FAILURE_MESSAGE_SFTP");
                    return;
                }

                CommunicationChannelResource.activate({
                        channelId: channel.id
                    }, null,
                    function(channels) {
                        // Activation response can also contain previous activated channel
                        if (channels.length === 0) {
                            growl.warning("COMMUNICATION_CHANNELS.ACTIVATE_FAILURE_MESSAGE_" + channel.type);
                        } else {
                            for (var i = 0; i < channels.length; i++) {
                                $scope.channels[channels[i].id] = channels[i];
                            }
                            growl.success("COMMUNICATION_CHANNELS.ACTIVATE_SUCCESS_MESSAGE");
                        }
                    },
                    function(response) {
                        $rootScope.error = response.data;
                        $scope.status = response.status;
                        growl.error("COMMUNICATION_CHANNELS.ACTIVATE_ERROR_MESSAGE");
                    });

            };

            $scope.deactivateChannel = function(channel) {

                console.log("about to deactivate channel: ", channel);

                CommunicationChannelResource.deactivate({
                        channelId: channel.id
                    }, null,
                    function(channel) {
                        $scope.channels[channel.id] = channel;
                        growl.success("COMMUNICATION_CHANNELS.DEACTIVATE_SUCCESS_MESSAGE");
                    },
                    function(response) {
                        $rootScope.error = response.data;
                        $scope.status = response.status;
                        growl.error("COMMUNICATION_CHANNELS.DEACTIVATE_ERROR_MESSAGE");
                    });

            };

            $scope.uploadInboundMessages = function(channel) {
                return uploadMessages(channel, 'INBOUND');
            };

            $scope.uploadOutboundMessages = function(channel) {
                return uploadMessages(channel, 'OUTBOUND');
            };

            function convert(loadedChannel) {
                var channel = loadedChannel;
                if (!_.isEmpty(channel.subDestinations)) {
                    var subDestinations = [];
                    for (var key in channel.subDestinations) {
                        var label = channel.subDestinations[key];
                        subDestinations.push({
                            key: key,
                            label: label
                        });
                    }
                    channel.subDestinations = subDestinations;
                } else {
                    channel.subDestinations = null;
                }

                if (!_.isEmpty(channel.blockedReviewers)) {
                    var blockedReviewers = [];
                    for (var blockedReviewerKey in channel.blockedReviewers) {
                        var blockedReviewerLabel = channel.blockedReviewers[blockedReviewerKey];
                        blockedReviewers.push({
                            key: blockedReviewerKey,
                            label: blockedReviewerLabel
                        });
                    }
                    channel.blockedReviewers = blockedReviewers;
                } else {
                    channel.blockedReviewers = null;
                }

                return channel;
            }

            function uploadMessages(channel, direction) {
                return {
                    url: lax_rest_url('messaging/messagingassets') + '?direction=' + direction,
                    formData: getFormData(channel),
                    autoUpload: true,
                    reset: true,
                    uploadComplete: function(response) {
                        growl.success("UPLOAD_SUCCESS_MESSAGE");
                    }
                };
            }

            function getFormData(channel) {
                var fd = [];
                fd.push({
                    "channelId": channel.id
                });
                return fd;
            }

            $scope.toggleShowPublicationMessages = function(entity) {
                entity.showDetails = !entity.showDetails;
            };

            $scope.canSubscribe = function(channel) {
                if (!$scope.getSubscriptionCategory(channel)) {
                    return false;
                }
                if (!$scope.getSubscriptionAttributes(channel)) {
                    return false;
                }
                return true;
            };

            $scope.getSubscriptionCategory = function(channel) {
                var plan = $scope.getPlan(channel);
                if (plan) {
                    return plan.subscriptionCategory;
                } else if (channel.format) {
                    var categoryName = $rootScope.dataModel.name() + "::" + "Subscription" + channel.format;
                    var subscriptionCategory = $rootScope.dataModel.category(categoryName);
                    return (subscriptionCategory || {}).name;
                } else {
                    return null;
                }
            };

            $scope.getSubscriptionAttributes = function(channel) {
                var plan = $scope.getPlan(channel);
                if (plan) {
                    return plan.subscriptionAttributes;
                } else if (channel.format) {
                    var subscriptionAttributes =
                        $rootScope.dataModel.filteredLayoutAttributes("subscription_" + channel.format);
                    return subscriptionAttributes;
                } else {
                    return [];
                }
            };

            $scope.showMessagingDetails = function(message) {
                $modal.open({
                    templateUrl: 'tpl/show-messaging-details.tpl.html',
                    controller: 'ShowMessagingDetailsCtrl',
                    backdrop: true,
                    windowClass: 'publish-modal',
                    resolve: {
                        message: function() {
                            return message;
                        }
                    }
                });
            };

            $scope.showErrorDetailDialog = function (channel) {
                var errorTitle = $rootScope.translate("ITEM_REVIEW.ERROR");
                // Note: Only SFTP channels can have connection error (at the moment)
                var errorHeader = $rootScope.translate("COMMUNICATION_CHANNELS.CONNECTION_FAILURE_MESSAGE_SFTP");
                var errorMessage = $scope.getConnectionError(channel);

                var dialog = ReactBridge.mountDialog("CommunicationChannelsErrorDialog", "#communication_channels_error_dialog-node",
                    {
                        title: errorTitle,
                        heading: errorHeader,
                        message: errorMessage,
                        onClose: function () {
                            dialog.unmount();
                        }
                    });
            };

            $scope.showErrorTooltip = function (channel) {
                // Note: Only SFTP channels can have connection error (at the moment)
                var errorHeader = $rootScope.translate("COMMUNICATION_CHANNELS.CONNECTION_FAILURE_MESSAGE_SFTP");

                function customRendererFn() {
                    var attributeStart = '<div class="item-reviews-tooltip"><ul><li class="error">';
                    var attributeEnd = '</li></ul></div><span class="hopscotch-arrow"></span>';
                    return attributeStart + errorHeader + attributeEnd;
                }

                var target = 'error_message-channel-' + channel.id;
                var calloutConfig = {
                    id: 'callout_' + channel.id,
                    target: target,
                    placement: 'left',
                    content: '',
                    customRenderer: customRendererFn
                };

                var calloutMgr = hopscotch.getCalloutManager();
                calloutMgr.removeAllCallouts();

                var calloutEle = calloutMgr.createCallout(calloutConfig);
                // Hack: override !important css rules, with inline precedence
                calloutEle.element.style.cssText += 'border: none !important; z-index: 1049;';
                calloutEle.element.addEventListener("click", function (e) {
                    $scope.hideErrorTooltip();
                    $scope.showErrorDetailDialog(channel);
                });
                $scope.shouldRemoveTooltip = false;
            };

            $scope.hideErrorTooltip = function () {
                $scope.shouldRemoveTooltip = true;
                $timeout(function () {
                    hideTooltip('.hopscotch-bubble');
                }, 300);
            };

            function hideTooltip(elementClass) {
                if ($(elementClass + ':hover').length == 0 &&
                    $scope.shouldRemoveTooltip) {
                    $(elementClass + ':hover').off('mouseout');
                    var calloutMgr = hopscotch.getCalloutManager();
                    calloutMgr.removeAllCallouts();
                } else {
                    $(elementClass).mouseout(function() {
                        $timeout(function() {
                            hideTooltip(elementClass);
                        }, 600);
                    });
                }
            }

            $scope.getConnectionError = function (channel) {
                return  "Error: " + channel.errorMessage;
            };

            $scope.hasConnectionError = function (channel) {
                return !_.isEmpty(channel.errorMessage);
            };

            $scope.clearConnectionError = function (channel) {
                channel.errorMessage = "";
            };

            if ($routeParams.id) {
                CommunicationChannelResource.get({
                        channelId: $routeParams.id
                },
                function(channel) {
                    if ($location.path().indexOf('subscriptions') > -1) {
                        $scope.newSubscription(channel);
                    } else {
                        $scope.editChannel(channel);
                    }
                });
            }

        }
    )
    .controller('EditChannelCtrl',
        function($modalInstance, $rootScope, $scope, $translate, channel, title, plans, types, templates, channelsVerification, uiGridConstants, Auth, CommunicationChannelTypesResource) {

            $scope.focusOn = true;
            $scope.channel = channel || {};
            $scope.channel.planValues = $scope.channel.planValues || {};
            $scope.title = title;
            $scope.plans = plans;
            $scope.plansArray = _.values(plans);
            $scope.types = types;
            $scope.directionOptions = [
                {label: "Inbound", value: "INBOUND_ONLY"},
                {label: "Outbound", value: "OUTBOUND_ONLY"},
                {label: "Both", value: "BOTH"}
            ];
            $scope.channelDirections = {
                AS2: {default: "BOTH", configurable: false},
                BSYNCED: {default: "BOTH", configurable: false},
                CUSTOM: {default: "OUTBOUND_ONLY", configurable: true},
                HTTPS: {default: "OUTBOUND_ONLY", configurable: false},
                SFTP: {default: "OUTBOUND_ONLY", configurable: true},
                SMTP: {default: "OUTBOUND_ONLY", configurable: false},
                WEBSERVICE: {default: "BOTH", configurable: false}
            };
            $scope.templates = templates;
            $scope.channelsVerification = channelsVerification;
            $scope.chosenType = $scope.channel.type;
            $scope.encryptAlgorithms = ['rc2', '3des'];
            $scope.signAlgorithms = ['sha1', 'md5'];
            $scope.channelsSupportingBlockedReviewers = ['AS2', 'BSYNCED', 'CUSTOM'];
            $scope.bsyncedServers = $rootScope.systemSettings.BSYNCED_SERVERS;
            $scope.channel.needsVerification = !$scope.channelsVerification[channel.type];
            $scope.channel.supportsBlockedReviewers = $scope.channelsSupportingBlockedReviewers.indexOf($scope.channel.type) > -1;
            $scope.hasCombinationError = false;
            $scope.yesNoOptions = [
                {
                    key: true,
                    label: $rootScope.translate('YES')
                }, {
                    key: false,
                    label: $rootScope.translate('NO')
                }
            ];

            if (!_.isEmpty($scope.channel.plan)) {
                var plan = _.find($scope.plans, function(p) {
                    return p.name == $scope.channel.plan;
                });
                if (_.isEmpty(plan)) {
                    $scope.additionalPlan = {name : $scope.channel.plan, label : $scope.channel.plan};
                    $scope.plansArray.push($scope.additionalPlan);
                }
            }

            $scope.ok = function() {
                $modalInstance.close($scope.channel);
            };

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            $scope.chooseType = function(channel, type) {
                var selectedPlan = $scope.plans[$scope.channel.plan];
                checkCombination(selectedPlan,type);
                var template = $scope.templates.filter(function(element) {
                    return element.name === type;
                })[0];
                if (template) {
                    $scope.channel = angular.extend($scope.channel, template);
                    $scope.item = $scope.channel.planValues;
                } else {
                    $scope.channel.type = type;
                }

                $scope.channel.direction = $scope.channelDirections[type].default;
                $scope.channel.needsVerification = !$scope.channelsVerification[$scope.channel.type];
                $scope.channel.supportsBlockedReviewers = $scope.channelsSupportingBlockedReviewers.indexOf($scope.channel.type) > -1;
            };

            $scope.changePlan = function(plan) {
                checkCombination(plan,$scope.channel.type);
            };

            function checkCombination(plan,type) {
                if (!_.isEmpty(plan) && !_.isEmpty(type)) {
                    var actions = [];
                    for (var key in plan.planActions) {
                        actions = _.union(actions, plan.planActions[key]);
                    }
                    $scope.hasCombinationError = actions.indexOf(type) < 0;
                } else {
                    $scope.hasCombinationError = false;
                }
            }

            $scope.clearValue = function(inputId) {
                if (!_.isNil(inputId) && !_.isNil($scope.channel[inputId])) {
                    $scope.channel[inputId] = null;
                }
            };

            $scope.item = $scope.channel.planValues;

            $scope.hasPlanAttributes = function(planName) {
                var planAttributes = $scope.getPlanAttributes(planName);
                return planAttributes.length > 0;
            };

            $scope.hasEditPermission = function(channel) {
                return Auth.hasChannelPermission("edit", channel);
            };

            $scope.hasEditSubDestinationPermission = function(channel) {
                return Auth.hasChannelPermission("edit_sub_destinations", channel);
            };

            $scope.getPlanAttributes = function(planName) {
                var plan = $scope.plans[planName];
                return plan ? plan.planAttributes : [];
            };

            $scope.supportsSubDestinations = function(planName) {
                var plan = $scope.plans[planName];
                return plan ? plan.supportsSubDestinations : false;
            };

            $scope.subDestinationsColumnDefs = [{
                field: 'key',
                displayName: $translate.instant('COMMUNICATION_CHANNELS.SUB_DESTINATIONS.KEY'),
                headerTooltip: true,
                cellTooltip: true,
                sort: {
                  direction: uiGridConstants.ASC,
                },
                width: '46%'
            }, {
                field: 'label',
                displayName: $translate.instant('COMMUNICATION_CHANNELS.SUB_DESTINATIONS.LABEL'),
                headerTooltip: true,
                cellTooltip: true,
                width: '46%'
            }, {
                field: 'actions',
                displayName: $translate.instant('ACTIONS'),
                headerTooltip: true,
                width: '8%',
                headerCellClass: 'text-right',
                cellClass: 'text-right',
                pinnedRight: true,
                allowCellFocus: false,
                enableCellEdit: false,
                enableColumnMenu: false,
                enableSorting: false,
                cellTemplate: '<div class="actions text-right">' +
                                '<button id="delete-sub-destination" type="button" ' +
                                    'class="btn btn-cell" ' +
                                    'data-ng-disabled="!grid.appScope.hasEditPermission(channel) && !grid.appScope.hasEditSubDestinationPermission(channel)" ' +
                                    'data-ng-click="grid.appScope.deleteSubDestination(row)" ' +
                                    'title="{{\'COMMUNICATION_CHANNELS.SUB_DESTINATIONS.DELETE\' | translate}}">' +
                                  '<span class="syncons syncons-delete"></span>' +
                                '</button>' +
                              '</div>'
            }];

            $scope.subDestinationsGrid = {
                data: 'channel.subDestinations',
                enableColumnMenus: false,
                enableColumnResize: true,
                enableRowSelection: false,
                minRowsToShow: 5,
                columnDefs: $scope.subDestinationsColumnDefs,
            };

            $scope.addSubDestination = function(subDestinationKey, subDestinationLabel) {
                $scope.channel.subDestinations = $scope.channel.subDestinations || [];
                $scope.channel.subDestinations.push({});
                return;
            };

            $scope.deleteSubDestination = function(row) {
                var index = $scope.channel.subDestinations.indexOf(row.entity);
                $scope.channel.subDestinations.splice(index, 1);
            };

            $scope.supportsBlockedReviewers = function(channel) {
                return channel ? channel.supportsBlockedReviewers : false;
            };

            $scope.blockedReviewersColumnDefs = [{
                field: 'key',
                displayName: $translate.instant('COMMUNICATION_CHANNELS.BLOCKED_REVIEWERS.KEY'),
                headerTooltip: true,
                cellTooltip: true,
                sort: {
                  direction: uiGridConstants.ASC,
                },
                width: '46%'
            }, {
                field: 'label',
                displayName: $translate.instant('COMMUNICATION_CHANNELS.BLOCKED_REVIEWERS.LABEL'),
                headerTooltip: true,
                cellTooltip: true,
                width: '46%'
            }, {
                field: 'actions',
                displayName: $translate.instant('ACTIONS'),
                headerTooltip: true,
                width: '8%',
                headerCellClass: 'text-right',
                cellClass: 'text-right',
                pinnedRight: true,
                allowCellFocus: false,
                enableCellEdit: false,
                enableColumnMenu: false,
                enableSorting: false,
                cellTemplate: '<div class="actions text-right">' +
                                '<button id="delete-blocked-reviewer" type="button" data-ng-disabled="!grid.appScope.hasEditPermission(channel) && !grid.appScope.hasEditSubDestinationPermission(channel)" ' +
                                'class="btn btn-cell" data-ng-click="grid.appScope.deleteBlockedReviewer(row)" title="{{\'COMMUNICATION_CHANNELS.BLOCKED_REVIEWERS.DELETE\' | translate}}">' +
                                  '<span class="syncons syncons-delete"></span>' +
                                '</button>' +
                              '</div>'
            }];

            $scope.blockedReviewersGrid = {
                data: 'channel.blockedReviewers',
                enableColumnMenus: false,
                enableColumnResize: true,
                enableRowSelection: false,
                minRowsToShow: 5,
                columnDefs: $scope.blockedReviewersColumnDefs,
            };

            $scope.addBlockedReviewer = function(blockedReviewerKey, blockedReviewerLabel) {
                $scope.channel.blockedReviewers = $scope.channel.blockedReviewers || [];
                $scope.channel.blockedReviewers.push({});
                return;
            };

            $scope.deleteBlockedReviewer = function(row) {
                var index = $scope.channel.blockedReviewers.indexOf(row.entity);
                $scope.channel.blockedReviewers.splice(index, 1);
            };

        }
    )
    .controller('ShowMessagingDetailsCtrl',
        function($rootScope, $scope, $translate, $modalInstance, $http, growl, message, MessagingResponsesResource) {

            $scope.message = message;

            function getAssetContent(path, callback) {
                $http({
                    method: 'GET',
                    url: path
                }).then(function(response) {
                    callback(response.data);
                }, function() {

                });
            }
            var prettifyXml = function(sourceXml) {
                var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
                var isNotValidXml = xmlDoc.getElementsByTagName("parsererror").length > 0;
                if (isNotValidXml) {
                    return sourceXml;
                }
                var xsltDoc = new DOMParser().parseFromString([
                    // describes how we want to modify the XML - indent everything
                    '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
                    '  <xsl:strip-space elements="*"/>',
                    '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
                    '    <xsl:value-of select="normalize-space(.)"/>',
                    '  </xsl:template>',
                    '  <xsl:template match="node()|@*">',
                    '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
                    '  </xsl:template>',
                    '  <xsl:output indent="yes"/>',
                    '</xsl:stylesheet>',
                ].join('\n'), 'application/xml');

                var xsltProcessor = new XSLTProcessor();
                xsltProcessor.importStylesheet(xsltDoc);
                var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
                var resultXml = new XMLSerializer().serializeToString(resultDoc);
                return resultXml;
            };

            getAssetContent(lax_rest_url('assets/') + message.path, function(data) {
                $scope.messageContent = prettifyXml(data);
            });

            if ($scope.message.responseId) {
                MessagingResponsesResource.get({
                    messagingAssetId: message.id
                },
                function(response) {

                    $scope.messagingResponse = response;

                    getAssetContent(lax_rest_url('messaging/messagingassets/') + $scope.messagingResponse.messagingAssetId,
                        function(data) {
                            $scope.messagingResponseContent = prettifyXml(data);
                        }
                    );
                });
            } else if ($scope.message.responseMessageId) {
                getAssetContent(lax_rest_url('messaging/messagingassets/') + $scope.message.responseMessageId,
                    function(data) {
                        $scope.messagingResponseContent = data;
                    }
                );
            }

            $scope.close = function() {
                $modalInstance.close();
            };

        });
